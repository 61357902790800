import { PencilIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import { useMemo, useState } from "react";
import { AgentType, AgentTypes, mapTypeToLabel } from "../../../api/agent.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../../utils/parseTimeStamp";
import { useLeadAgentContext } from "../../fabrk/hooks/LeadAgentContext";
import { useLeadContext } from "../../fabrk/hooks/LeadContext";
import { useMessageContext } from "../../fabrk/hooks/MessageContext";
import FabrkMenuDivider from "../../fabrk/layout/FabrkMenuDivider";
import { useEventStreaming } from "../../message/StreamMessageContext";
import IconButton from "../../shared/buttons/IconButton";
import SubmitButton from "../../shared/buttons/SubmitButton";
import BaseChip from "../../shared/chip/BaseChip";
import { CopyToClipboardIconButton } from "../../shared/forms/CopyToClipboard";
import GenericSelect from "../../shared/forms/GenericSelect";
import {
  InputBase,
  NumberInputBase,
  TextArea,
} from "../../shared/forms/input/InputBase";
import {
  DataValueBase,
  HeadingBase,
  LabelBase,
} from "../../shared/typography/TypographyBase";
import { useAgentContext } from "../hooks/AgentContext";
import { agentTypeIcons } from "../view/agentTypeToIconMap";

function AgentDetails() {
  const [isEditing, setIsEditing] = useState(false);

  const { updateAgent, activeAgent, isCompanyAgent } = useAgentContext();
  const { update } = useLeadContext();

  const { persona } = useMessageContext();

  const { setCycles, cycles } = useEventStreaming();

  const { activeLeadAgent } = useLeadAgentContext();

  const [agentName, setAgentName] = useState(activeAgent?.name || "");

  const [clientName, setClientName] = useState("");

  const [type, setType] = useState<AgentType | null>(activeAgent?.type || null);

  useMemo(() => {
    if (activeAgent) {
      setAgentName(activeAgent.name);
      setType(activeAgent?.type || null);
    }
    if (
      activeLeadAgent &&
      activeLeadAgent.lead &&
      activeLeadAgent?.lead?.first_name &&
      activeLeadAgent?.lead?.last_name
    ) {
      setClientName(
        activeLeadAgent.lead?.first_name +
          " " +
          activeLeadAgent.lead?.last_name,
      );
    }
  }, [activeAgent, activeLeadAgent]);

  const [goal, setGoal] = useState(activeAgent?.goal || "");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      isCompanyAgent &&
      (agentName !== activeAgent?.name ||
        goal !== activeAgent?.goal ||
        type !== activeAgent?.type)
    ) {
      updateAgent({
        id: activeAgent?.id as string,
        ...(agentName !== activeAgent?.name && {
          name: agentName,
        }),
        ...(goal !== activeAgent?.goal && {
          goal: goal,
        }),
        ...(type &&
          type !== activeAgent?.type && {
            type: type,
          }),
      });
    }

    if (
      clientName !==
      activeLeadAgent?.lead?.first_name + " " + activeLeadAgent?.lead?.last_name
    ) {
      const firstName = clientName.split(" ")[0];
      const lastName = clientName.split(" ")[1];

      update({
        id: activeLeadAgent?.lead_id as string,
        values: { first_name: firstName, last_name: lastName },
      });
    }

    setIsEditing(false);
  };

  return (
    <div>
      <div className="flex flex-row justify-between">
        <HeadingBase>Details</HeadingBase>
        {isCompanyAgent && (
          <IconButton
            onClick={() => {
              setIsEditing(!isEditing);
              setAgentName(activeAgent?.name || "");
            }}
          >
            <PencilIcon className="h-4 w-4" />
          </IconButton>
        )}
      </div>
      <FabrkMenuDivider />
      <form onSubmit={handleSubmit}>
        <div className="mt-4 flex w-full flex-col gap-4">
          <div className="flex flex-row items-center justify-between gap-2">
            <div>
              <LabelBase>Agent Name</LabelBase>
              {isEditing ? (
                <InputBase value={agentName} setValue={setAgentName} />
              ) : (
                <DataValueBase>{activeAgent?.name}</DataValueBase>
              )}
            </div>
            <div>
              <LabelBase>Created</LabelBase>
              <DataValueBase>
                {formatTimeStampToHumanReadableShortDateTime(
                  activeAgent?.created_at,
                )}
              </DataValueBase>
            </div>
          </div>
          <div>
            <LabelBase>Agent ID</LabelBase>
            <div className="flex w-fit flex-row items-center gap-2">
              <DataValueBase>{activeAgent?.id}</DataValueBase>
              <CopyToClipboardIconButton text={activeAgent?.id as string} />
            </div>
          </div>
          {activeLeadAgent && (
            <div>
              <LabelBase>Client Name</LabelBase>
              {isEditing ? (
                <InputBase value={clientName || ""} setValue={setClientName} />
              ) : (
                <DataValueBase>
                  {activeLeadAgent &&
                  activeLeadAgent.lead &&
                  activeLeadAgent?.lead?.first_name &&
                  activeLeadAgent?.lead?.last_name
                    ? activeLeadAgent?.lead?.first_name +
                      " " +
                      activeLeadAgent?.lead?.last_name
                    : ""}
                </DataValueBase>
              )}
            </div>
          )}

          {activeLeadAgent && activeLeadAgent.lead_id && (
            <div>
              <LabelBase>Client ID</LabelBase>
              <div className="flex w-fit flex-row items-center gap-2">
                <DataValueBase>{activeLeadAgent?.lead_id}</DataValueBase>
                <CopyToClipboardIconButton
                  text={activeLeadAgent?.lead_id as string}
                />
              </div>
            </div>
          )}
          {isEditing ? (
            <div className="w-fit">
              <GenericSelect
                values={AgentTypes}
                setSelected={(value) => setType(value as AgentType)}
                selected={type as string}
              />
            </div>
          ) : (
            <div>
              {activeAgent?.type && (
                <div>
                  <LabelBase>Type</LabelBase>

                  <BaseChip>
                    <div className="flex w-full flex-row gap-2">
                      <div>{agentTypeIcons[activeAgent.type]}</div>
                      <div>{mapTypeToLabel(activeAgent?.type)}</div>
                    </div>
                  </BaseChip>
                </div>
              )}
            </div>
          )}
          <div>
            <Tooltip
              placement="top"
              content="This is the goal of this agent. This helps you to keep track of the purpose of this agent. This is not passed into the AI model."
              className="max-w-md bg-skin-hover text-skin-base"
            >
              <div>
                <LabelBase>Explanation</LabelBase>
              </div>
            </Tooltip>
            {isEditing ? (
              <TextArea value={goal} setValue={setGoal} />
            ) : (
              <DataValueBase>{activeAgent?.goal}</DataValueBase>
            )}
          </div>

          {persona && (
            <div>
              <div>
                <LabelBase>Cycles</LabelBase>
              </div>

              {isEditing ? (
                <NumberInputBase value={cycles} setValue={setCycles} />
              ) : (
                <DataValueBase>{cycles}</DataValueBase>
              )}
            </div>
          )}
          {isEditing && (
            <div className="flex justify-end">
              <div>
                <SubmitButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={
                    agentName === activeAgent?.name &&
                    goal === activeAgent?.goal &&
                    type === activeAgent?.type &&
                    clientName ===
                      activeLeadAgent?.lead?.first_name +
                        " " +
                        activeLeadAgent?.lead?.last_name
                  }
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default AgentDetails;
