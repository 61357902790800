/* eslint-disable react-hooks/exhaustive-deps */
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { createCheckoutSession, getSessionStatus } from "../../api/stripe.api";
import { useSessionContext } from "../fabrk/hooks/FabrkSessionContext";
import SubmitButton from "../shared/buttons/SubmitButton";
import { HeadingBase } from "../shared/typography/TypographyBase";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(stripePublicKey as string);

export const CheckoutForm = () => {
  const [searchParams] = useSearchParams();

  const amount = searchParams.get("amount");

  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    if (amount) {
      const session = await createCheckoutSession({
        amount: Number(amount),
      });

      return session.clientSecret;
    } else {
      const session = await createCheckoutSession({
        amount: 25,
      });

      return session.clientSecret;
    }
  }, [amount]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout" className="h-screen bg-skin-fill p-4">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout className="p-4" />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export const Return = () => {
  const [status, setStatus] = useState<string | null>(null);
  const [customerEmail, setCustomerEmail] = useState("");

  const navigate = useNavigate();
  const { companyId } = useSessionContext();

  async function getStatus() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    if (sessionId) {
      const status = await getSessionStatus(sessionId);
      setStatus(status.status);
      setCustomerEmail(status.customer_email);
    }
  }

  useEffect(() => {
    getStatus();
  }, []);

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <section
        id="success"
        className="flex flex-col gap-4 rounded-lg border-2 p-8"
      >
        <HeadingBase>Thank you for your purchase!</HeadingBase>
        <p>
          We appreciate your business! A confirmation email will be sent to{" "}
          {customerEmail}. If you have any questions, please email{" "}
          <a href="mailto:kevin@fabrk.ai">kevin@fabrk.ai</a>.
        </p>
        <SubmitButton onClick={() => navigate(`/project/${companyId}#billing`)}>
          View Payment History
        </SubmitButton>
      </section>
    );
  }

  return null;
};
