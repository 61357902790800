/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import FabrkMenuDivider from "../fabrk/layout/FabrkMenuDivider";
import { EditIcon } from "../shared/icons/icons";
import EditParameters from "./EditParameters";
import { useToolContext } from "./hooks/ToolContext";
import ToolParametersForm from "./ToolParametersForm";

function StandardParametersForm() {
  const { tool, isCompanyTool } = useToolContext();

  const [editParams, setEditParams] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <div>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-skin-base/40">
            Data passed into this tool by the AI
          </p>
        </div>
        {isCompanyTool && (
          <button onClick={() => setEditParams(!editParams)}>
            <EditIcon className="h-5 w-5 text-skin-base" />
          </button>
        )}
      </div>
      <FabrkMenuDivider />
      <div>
        {editParams ? (
          <EditParameters
            defaultParameters={tool?.parameters}
            setIsEdit={setEditParams}
          />
        ) : (
          <div>
            {tool?.parameters ? (
              <ToolParametersForm params={tool?.parameters} />
            ) : (
              <p>No parameters listed</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default StandardParametersForm;
