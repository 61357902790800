import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  CreateExtractionSchema,
  ExtractionSchemaAgentRow,
  ExtractionSchemaRow,
} from "./extractionSchema.type";

export function getExtractionSchemas(
  companyId: string,
): Promise<ExtractionSchemaRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionSchema +
    "/company/" +
    companyId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ExtractionSchemaRow[]>(url, request);
}

export function createExtractionSchema(values: CreateExtractionSchema) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.extractionSchema;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(values),
  });

  return fetchData<ExtractionSchemaRow>(url, request);
}

export function updateExtractionSchema(values: CreateExtractionSchema) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionSchema +
    "/" +
    values.id;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<ExtractionSchemaRow>(url, request);
}

export function deleteExtractionSchema(id: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionSchema +
    "/fabrk/" +
    id;

  const request = setHeaderOptions({ method: "DELETE" });

  return fetchData<ExtractionSchemaRow>(url, request);
}

export function getExtractionSchemaCampaigns(agentId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionSchemaAgent +
    "/agent/" +
    agentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ExtractionSchemaAgentRow[]>(url, request);
}

export function getExtractionSchemaAgentByTool(toolId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionSchemaAgent +
    "/tool/" +
    toolId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ExtractionSchemaAgentRow[]>(url, request);
}

export function createCampaignExtractionSchema({
  agentId,
  extractionSchemaId,
}: {
  agentId: string;
  extractionSchemaId: string;
}) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionSchemaAgent +
    "/fabrk";

  const request = {
    method: "POST",
    body: JSON.stringify({ agentId, extractionSchemaId }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ExtractionSchemaAgentRow>(url, request);
}

export function deleteCampaignExtractionSchema(
  extractionSchemaCampaignId: string,
) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionSchemaAgent +
    "/" +
    extractionSchemaCampaignId;

  const request = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ExtractionSchemaAgentRow>(url, request);
}
