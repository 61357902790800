/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import {
  createApiKey,
  getApiKeyByCompanyId,
  getCompanyById,
  getCompanyConfig,
  updateCompanyById,
  updateCompanyConfig,
} from "../../../api/company.api";
import {
  ApiKeyResponse,
  CompanyConfigRow,
  CompanyRow,
} from "../../../api/company.type";
import { roundToTwoDecimalPlaces } from "../../../utils/currencyManipulation";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useSessionContext } from "./FabrkSessionContext";

export const CompanyContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();

  const queryClient = useQueryClient();

  const { companyId } = useSessionContext();

  const [company, setCompany] = useState<CompanyRow>();

  const { mutate: updateCompany } = useMutation(updateCompanyById, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("leadAgents");
      queryClient.invalidateQueries("getLead");
      getCompany(companyId);
      setAlertProps({
        message: "Company updated",
        color: "green",
      });
    },
    onError(error: Error) {},
  });

  const [apiKey, setApiKey] = useState<ApiKeyResponse>();

  const { mutate: getKey } = useMutation(getApiKeyByCompanyId, {
    onSuccess: async (res) => {
      setApiKey(res);
    },
  });

  const { mutate: createKey } = useMutation(createApiKey, {
    onSuccess: async (res) => {
      setAlertProps({
        message: "API Key generated",
        color: "green",
      });
      setApiKey(res);
    },
  });

  const { mutate: getCompany } = useMutation(getCompanyById, {
    onSuccess: async (res) => {
      setCompany(res);
    },
    onError(error: Error) {},
  });

  const { mutate: getConfig, data: companyConfig } =
    useMutation(getCompanyConfig);

  const { mutate: updateConfig } = useMutation(updateCompanyConfig, {
    onSuccess: async (res) => {
      setAlertProps({
        message: "Company config updated",
        color: "green",
      });

      getConfig();
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (!company && companyId) {
      getCompany(companyId);
    }
    if (!apiKey && companyId) {
      getKey();
    }
  }, [companyId]);

  useEffect(() => {
    if (company) {
      getConfig();
    }
  }, [company]);

  const balance =
    company?.customer && company.customer[0]
      ? roundToTwoDecimalPlaces(company?.customer[0]?.fabrk_balance)
      : "0";

  const value = {
    company,
    updateCompany,
    getCompany,
    apiKey,
    createKey,
    balance,
    companyConfig,
    updateConfig,
  };

  return (
    <companyContext.Provider value={value}>
      {props.children}
    </companyContext.Provider>
  );
};

export const companyContext = createContext({
  company: {} as CompanyRow | undefined,
  updateCompany: {} as UseMutateFunction<
    CompanyRow,
    Error,
    Partial<CompanyRow>,
    unknown
  >,
  companyConfig: {} as CompanyConfigRow | undefined,
  getCompany: {} as UseMutateFunction<CompanyRow, Error, string, unknown>,
  apiKey: {} as ApiKeyResponse | undefined,
  createKey: {} as UseMutateFunction<ApiKeyResponse, unknown, void, unknown>,
  balance: "0",
  updateConfig: {} as UseMutateFunction<
    CompanyConfigRow,
    unknown,
    {
      id: string;
      values: Partial<CompanyConfigRow>;
    },
    unknown
  >,
});

export const useCompanyContext = () => useContext(companyContext);
