import { AgentAgentToolRow } from "./agentAgentTool.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";

export function getAgentAgentToolsByParent(
  agentId: string,
): Promise<AgentAgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.agentAgentTool + "/" + agentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<AgentAgentToolRow[]>(url, request);
}

export function addAgentTool({
  parentAgentId,
  childAgentId,
}: {
  parentAgentId: string;
  childAgentId: string;
}): Promise<AgentAgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentAgentTool +
    "/parent/" +
    parentAgentId +
    "/child/" +
    childAgentId;

  const request = { method: "PUT" };

  return fetchData<AgentAgentToolRow[]>(url, request);
}

export function deleteAiCampaignFunction({
  agentId,
  toolId,
}: {
  agentId: string;
  toolId: string;
}): Promise<AgentAgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/agent/" +
    agentId +
    "/tool/" +
    toolId;

  const request = { method: "DELETE" };

  return fetchData<AgentAgentToolRow[]>(url, request);
}
