/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  createLeadAgentWithExistingLead,
  createLeadAgentWithNewLead,
  getLeadAgentsByCompany,
  getLeadAgentsByLeadId,
} from "../../../api/leadAgent.api";
import { LeadAgentRow } from "../../../api/leadAgent.type";
import { useSessionContext } from "./FabrkSessionContext";
import { useLeadContext } from "./LeadContext";

export const LeadAgentContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { companyId } = useSessionContext();

  const queryClient = useQueryClient();

  const { lead } = useLeadContext();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [globalLoading, setGlobalLoading] = useState(false);

  const [activeLeadAgent, setActiveLeadAgent] = useState<LeadAgentRow | null>();

  // These are leadAgents that match the user's company. These are the agents that should be fully accessible to the user.
  const {
    data: companyLeadAgents,
    isLoading: isLoadingLeadAgents,
    refetch: refetchCompanyLeadAgents,
  } = useQuery({
    queryFn: async () => await getLeadAgentsByCompany(companyId as string),
    queryKey: ["companyLeadAgents", companyId],
    enabled: companyId ? true : false,
    onSettled: () => {
      setGlobalLoading(false);
    },
  });

  // These are the lead agents that match the current lead. These will have overlapping data with the companyLeadAgents. However, they will include leadAgents that were created by an action and not the agent will not be fully accessible to the user without forking.
  const {
    data: leadAgents,
    isLoading,
    refetch: refetchLeadAgents,
  } = useQuery({
    queryFn: async () => await getLeadAgentsByLeadId(lead?.id as string),
    queryKey: ["leadAgents", lead?.id],
    enabled: lead?.id ? true : false,
    onSettled: () => {
      setGlobalLoading(false);
    },
  });

  const refetchData = () => {
    refetchCompanyLeadAgents();
    refetchLeadAgents();
  };

  const [newLeadAgent, setNewLeadAgent] = useState<LeadAgentRow>();

  // This uses the exiting lead and should be used in cases where there isn't a user yet. This should be used sparingly as it can cause confusion if the same lead is used for multiple agents.
  const { mutate: createLeadAgent, isLoading: newLeadAgentLoading } =
    useMutation(createLeadAgentWithExistingLead, {
      onSuccess: async (res) => {
        setNewLeadAgent(res);
        queryClient.invalidateQueries("leadAgents");
        queryClient.invalidateQueries("companyAgents");
      },
      onError(error: Error) {
        setErrorMessage(error.message);
      },
      onSettled: () => {
        setTimeout(() => {
          setNewLeadAgent(undefined);
        }, 1000);
      },
    });

  // The creates a new lead each time and should be used if the users is logged in
  const { mutate: createNewLeadAgent, isLoading: loading } = useMutation(
    createLeadAgentWithNewLead,
    {
      onSuccess: async (res) => {
        setNewLeadAgent(res);
        queryClient.invalidateQueries("leadAgents");
        queryClient.invalidateQueries("companyAgents");
      },
      onError(error: Error) {
        setErrorMessage(error.message);
      },
      onSettled: () => {
        setTimeout(() => {
          setNewLeadAgent(undefined);
        }, 1000);
      },
    },
  );

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    }
  }, [errorMessage]);

  const value = {
    companyLeadAgents,
    leadAgents,
    activeLeadAgent,
    loading:
      isLoading ||
      globalLoading ||
      newLeadAgentLoading ||
      loading ||
      isLoadingLeadAgents,
    setGlobalLoading,
    errorMessage,
    setErrorMessage,
    createLeadAgent,
    setActiveLeadAgent,
    refetchData,

    newLeadAgent,
    createNewLeadAgent,
  };

  return (
    <LeadAgentContext.Provider value={value}>
      {children}
    </LeadAgentContext.Provider>
  );
};

export const LeadAgentContext = createContext({
  leadAgents: {} as LeadAgentRow[] | undefined,
  companyLeadAgents: {} as LeadAgentRow[] | undefined,
  activeLeadAgent: {} as LeadAgentRow | null | undefined,

  loading: false,
  setGlobalLoading: {} as (loading: boolean) => void,
  errorMessage: "",
  setErrorMessage: {} as (message: string) => void,
  createLeadAgent: {} as UseMutateFunction<
    LeadAgentRow,
    Error,
    {
      agentId: string;
      leadId: string;
    },
    unknown
  >,
  setActiveLeadAgent: {} as React.Dispatch<
    React.SetStateAction<LeadAgentRow | null | undefined>
  >,
  refetchData: {} as () => void,
  newLeadAgent: {} as LeadAgentRow | undefined,
  createNewLeadAgent: {} as UseMutateFunction<
    LeadAgentRow,
    Error,
    {
      agentId: string;
    },
    unknown
  >,
});

export const useLeadAgentContext = () => useContext(LeadAgentContext);
