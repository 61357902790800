/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";
import { useMutation } from "react-query";
import { getExtractionResponsesByMessageId } from "../../../../api/extractionResponse.api";
import { ExtractionResponseRow } from "../../../../api/extractionResponse.type";
import { getSmsMetadataById } from "../../../../api/smsMetadata.api";
import { SmsMetadataRow } from "../../../../api/smsMetadata.type";

export const MessageMetadataContextWrapper = ({
  assistantMessageId,
  userMessageId,
  children,
}: {
  assistantMessageId: string;
  userMessageId: string;
  children: React.ReactNode;
}) => {
  const [systemMessages, setSystemMessages] = useState<
    SmsMetadataRow[] | undefined
  >();
  const [functionMessages, setFunctionMessages] = useState<
    SmsMetadataRow[] | undefined
  >();
  const [toolMessages, setToolMessages] = useState<
    SmsMetadataRow[] | undefined
  >();

  const { mutate: getMetadataMessages, isLoading: metadataLoading } =
    useMutation(getSmsMetadataById, {
      onSuccess: async (res) => {
        setSystemMessages(res.filter((msg) => msg.role === "system"));
        setFunctionMessages(res.filter((msg) => msg.role === "function"));
        setToolMessages(res.filter((msg) => msg.role === "tool"));
      },
    });

  const {
    mutate: getExtractionResponse,
    data: responses,
    isLoading: responseLoading,
  } = useMutation(getExtractionResponsesByMessageId);

  function handleGetData() {
    getMetadataMessages({ messageId: assistantMessageId });
    getExtractionResponse(userMessageId);
  }

  const value = {
    handleGetData,
    responses,
    loading: responseLoading || metadataLoading,
    systemMessages,
    functionMessages,
    toolMessages,
  };

  return (
    <messageMetadataContext.Provider value={value}>
      {children}
    </messageMetadataContext.Provider>
  );
};

export const messageMetadataContext = createContext({
  handleGetData: () => {},
  responses: [] as ExtractionResponseRow[] | undefined,
  loading: false,
  systemMessages: [] as SmsMetadataRow[] | undefined,
  functionMessages: [] as SmsMetadataRow[] | undefined,
  toolMessages: [] as SmsMetadataRow[] | undefined,
});

export const useMessageMetadataContext = () =>
  useContext(messageMetadataContext);
