/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AgentRow, AgentType, mapTypeToLabel } from "../../api/agent.type";
import { useAgentContext } from "../agent/hooks/AgentContext";
import { useLoadCompanyAgents } from "../agent/hooks/useCompanyAgents";
import { useLeadAgentContext } from "../fabrk/hooks/LeadAgentContext";
import { useLeadContext } from "../fabrk/hooks/LeadContext";
import ThreadListFilter from "./ThreadListFilter";
import ThreadNavItemWithSubList from "./ThreadNavItemWithSubList";
import AgentThreadList from "./UserLeadCampaigns";

function ThreadListContainer() {
  const { agentId, lcId } = useParams();

  const { lead } = useLeadContext();

  const { setActiveLeadAgent, leadAgents, companyLeadAgents } =
    useLeadAgentContext();

  const { companyAgents } = useLoadCompanyAgents();

  const [nonCompanyAgents, setNonCompanyAgents] = useState<AgentRow[]>([]);

  const [selectedAgentType, setSelectedAgentType] = useState<AgentType | "all">(
    "all",
  );
  const [selectTypeOpen, setSelectTypeOpen] = useState(false);

  const { getAgent, activeAgent } = useAgentContext();

  useEffect(() => {
    if (agentId && lead) {
      getAgent({
        id: agentId,
        leadId: lead?.id,
      });
    }
  }, [agentId, companyAgents, lead]);

  useEffect(() => {
    if (lcId) {
      const foundLc = leadAgents?.find((lc) => lc?.id === lcId);

      if (foundLc) {
        setActiveLeadAgent(foundLc);
      } else {
        const matchingLc = activeAgent?.lead_agent?.find(
          (lc) => lc?.id === lcId,
        );

        if (matchingLc) {
          setActiveLeadAgent(matchingLc);
        }
      }
    }
  }, [leadAgents, lcId, activeAgent, lead]);

  // This handles getting the leadAgents that are not part of the company and mapping them to an agent so they show in the thread list. These are leadAgents that were likely created by an action.
  useEffect(() => {
    if (
      companyAgents &&
      leadAgents &&
      leadAgents.length > 0 &&
      companyAgents.length > 0 &&
      leadAgents.length > companyAgents.length
    ) {
      const allNonDuplicateLeadAgents = leadAgents?.filter((leadAgent) => {
        return !companyLeadAgents?.some((companyLeadAgent) => {
          return companyLeadAgent.id === leadAgent.id;
        });
      });

      const leadAgentToAgentMap = allNonDuplicateLeadAgents.map((leadAgent) => {
        return {
          ...leadAgent.agent,
          lead_agent: [leadAgent],
        };
      }) as AgentRow[];
      setNonCompanyAgents(leadAgentToAgentMap);
    }
  }, [leadAgents, companyAgents]);

  // Filter the agents by the selected agent type
  const filterAgentsByType = (agents: AgentRow[] | undefined) => {
    if (selectedAgentType === "all") return agents;
    return agents?.filter((agent) => agent.type === selectedAgentType);
  };

  return (
    <>
      <div className="flex flex-row">
        <div className="flex w-full flex-row justify-between">
          <p className="text-xs font-semibold leading-6 text-skin-base">
            Agent Threads
          </p>

          <ThreadListFilter
            setSelectTypeOpen={setSelectTypeOpen}
            selectedAgentType={selectedAgentType}
            selectTypeOpen={selectTypeOpen}
            setSelectedAgentType={setSelectedAgentType}
          />
        </div>
      </div>
      {selectedAgentType !== "all" && (
        <div>
          <p className="text-xs font-semibold capitalize leading-6 text-skin-base">
            {mapTypeToLabel(selectedAgentType)}
          </p>
          <p className="text-xs capitalize leading-6 text-skin-base">
            Count: {filterAgentsByType(companyAgents)?.length}
          </p>
        </div>
      )}
      <div className="h-[440px] w-full overflow-y-auto overflow-x-hidden rounded-lg p-2">
        <ul className="-mx-2 mt-2 space-y-1">
          <li className="relative">
            {activeAgent && (
              <ThreadNavItemWithSubList agent={activeAgent} isActive={true} />
            )}
          </li>
          {companyAgents && (
            <AgentThreadList
              activeAgent={activeAgent}
              agents={filterAgentsByType(companyAgents)}
            />
          )}
          {nonCompanyAgents && nonCompanyAgents.length > 0 && (
            <AgentThreadList
              activeAgent={activeAgent}
              agents={filterAgentsByType(nonCompanyAgents)}
            />
          )}
        </ul>
      </div>
    </>
  );
}

export default ThreadListContainer;
