/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { getActionById } from "../../api/action.api";
import { ActionRow } from "../../api/action.type";
import { randomName } from "../../utils/randomNames";
import { useSessionContext } from "../fabrk/hooks/FabrkSessionContext";
import { useLeadAgentContext } from "../fabrk/hooks/LeadAgentContext";
import { useLeadContext } from "../fabrk/hooks/LeadContext";
import { useMessageContext } from "../fabrk/hooks/MessageContext";
import FabrkMenuDivider from "../fabrk/layout/FabrkMenuDivider";
import SubmitButton from "../shared/buttons/SubmitButton";

function ActionCard({ action }: { action: ActionRow }) {
  const navigate = useNavigate();
  const { createLeadAgent, newLeadAgent, leadAgents } = useLeadAgentContext();
  const { lead } = useLeadContext();

  const { companyId, createCompanyIfNeeded } = useSessionContext();
  const { setAction } = useMessageContext();

  const { mutate: getAction } = useMutation(getActionById, {
    onSuccess: async (res) => {
      if (res) {
        setAction(res);
      }
    },
    onError(error: Error) {
      console.log(error);
    },
  });

  function handleRunAction() {
    getAction(action.id);

    if (!companyId) {
      createCompanyIfNeeded({ leadId: lead?.id as string, name: randomName() });
    }

    let found;
    if (leadAgents) {
      found = leadAgents.find((lc) => lc.agent_id === action.agent_id);
    }

    if (found) {
      navigate(`/a/${found.agent_id}/t/${found.id}`);
    } else {
      createLeadAgent({ agentId: action.agent_id, leadId: lead?.id as string });
    }
  }

  useEffect(() => {
    if (newLeadAgent) {
      navigate(`/a/${newLeadAgent.agent_id}/t/${newLeadAgent.id}}`);
    }
  }, [newLeadAgent]);

  return (
    <div
      key={action.id}
      className="bg-fill-muted group relative flex flex-col overflow-hidden rounded-lg border border-skin-hover shadow-lg"
    >
      <div className="aspect-h-4 aspect-w-3 sm:aspect-none bg-skin-accentSecondary group-hover:opacity-75 sm:h-40">
        <img
          alt={action.agent?.name}
          src={action.image_path || "/images/woven-tech.png"}
          className="h-full w-full object-cover object-center sm:h-full sm:w-full"
        />
      </div>
      <div className="flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-sm font-medium text-skin-base">
          <span aria-hidden="true" />
          {action?.agent?.name}
        </h3>
        <p className="text-sm text-skin-base/80">{action.agent?.goal}</p>
        <FabrkMenuDivider />
        <div className="flex flex-1 flex-col items-center justify-end gap-2">
          <p className="text-md font-semibold text-skin-base">
            {action.content || action.sms_message?.content}
          </p>
          <p className="text-sm text-skin-base">{action.run_count} Runs</p>
        </div>
      </div>
      <div className="p-2">
        <SubmitButton onClick={handleRunAction}>Run</SubmitButton>
      </div>
    </div>
  );
}

export default ActionCard;
