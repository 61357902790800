/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */

import { Typography } from "@material-tailwind/react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAgentToolContext } from "../fabrk/hooks/AgentToolContext";
import { useSessionContext } from "../fabrk/hooks/FabrkSessionContext";
import { useAlertContext } from "../shared/alert/alertContext";
import SubmitButton from "../shared/buttons/SubmitButton";
import FileUploadInput from "../shared/forms/FileUploadInput";
import {
  FileUploadContextWrapper,
  useFileUploadContext,
} from "../shared/forms/hooks/fileUploadContext";
import { useToolContext } from "./hooks/ToolContext";

function _AddFileToTool({ setEdit }: { setEdit: (edit: boolean) => void }) {
  const { companyId } = useSessionContext();
  const { setGlobalLoading } = useAlertContext();

  const fileTypes = [".csv", ".pdf", ".docx", ".doc", ".txt", ".md", ".json"];

  const {
    files,
    filePaths,
    uploadMultiple,
    handleOpenFileSelect,
    hiddenFileInput,
    handleMultipleChange,
    setFiles,
  } = useFileUploadContext();

  const { addFile } = useAgentToolContext();
  const { tool } = useToolContext();

  const [searchParams] = useSearchParams();

  const agentId = searchParams.get("agentId");

  const onSubmit = () => {
    setGlobalLoading(true);
    addFile({
      urls: filePaths,
      companyId,
      toolId: tool?.id as string,
      name: "file",
      agentId: agentId as string,
    });
    setFiles([]);
    setEdit(false);
  };

  useEffect(() => {
    if (files && files.length !== filePaths.length) {
      uploadMultiple({ files });
    }
  }, [files]);

  return (
    <div className="max-w-2xl">
      <div className="flex w-full flex-col gap-4">
        <SubmitButton
          type="submit"
          variant="outlined"
          onClick={(e) => {
            e.preventDefault();
            handleOpenFileSelect(e);
          }}
        >
          Upload files
        </SubmitButton>
        <Typography variant="small" className="text-skin-base">
          File types allowed: {fileTypes.join(", ")}
        </Typography>
      </div>
      <div className="p-5">
        <ul className="text-skin-base">
          {files &&
            files?.map((file, index) => <li key={index}>{file.name}</li>)}
        </ul>
      </div>

      <SubmitButton
        disabled={!filePaths.length}
        type="submit"
        onClick={onSubmit}
      >
        Submit
      </SubmitButton>

      <FileUploadInput
        handleChange={handleMultipleChange}
        hiddenFileInput={hiddenFileInput}
        accept={fileTypes.join(",")}
        multiple={true}
      />
    </div>
  );
}

const AddFilesToTool = ({ setEdit }: { setEdit: (edit: boolean) => void }) => {
  return (
    <FileUploadContextWrapper>
      <_AddFileToTool setEdit={setEdit} />
    </FileUploadContextWrapper>
  );
};

export default AddFilesToTool;
