import { UserCircleIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { useSession } from "@supabase/auth-helpers-react";
import { useState } from "react";
import GenericDialog from "../../../shared/dialog/GenericDialog";
import { useAuthContext } from "../../hooks/AuthContext";
import { useCompanyContext } from "../../hooks/CompanyContext";
import FabrkMenuDivider from "../FabrkMenuDivider";

function UserDetails() {
  const { handleLogout } = useAuthContext();
  const session = useSession();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { company, balance, companyConfig } = useCompanyContext();

  return (
    <div className="flex flex-row items-center justify-end">
      <Menu>
        <MenuHandler>
          <Button variant="outlined" className="bg-skin-fill p-1" size="sm">
            <UserCircleIcon className="h-6 w-6 text-skin-base" />
          </Button>
        </MenuHandler>
        <MenuList className="bg-skin-fill text-skin-base">
          <MenuItem>
            <a className="text-skin-base" href={`/user/${session?.user.id}`}>
              {session?.user.email}
            </a>
          </MenuItem>
          <MenuItem>
            <a
              className="text-skin-base"
              href={`/project/${company?.id}#billing`}
            >
              Balance: ${balance}
            </a>
          </MenuItem>
          {companyConfig && !companyConfig?.openai_api_key_added && (
            <MenuItem>
              <a
                className="text-skin-base"
                href={`/project/${company?.id}#overview`}
              >
                Add Open AI api key
              </a>
            </MenuItem>
          )}
          <FabrkMenuDivider />
          <MenuItem onClick={handleLogout}>Log out</MenuItem>
        </MenuList>
      </Menu>
      <GenericDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        title={"Add your Open AI key"}
      >
        <div className="flex w-full flex-col gap-2">Add Open AI key</div>
      </GenericDialog>
    </div>
  );
}

export default UserDetails;
