import { CompanyContextWrapper } from "../fabrk/hooks/CompanyContext";
import { ExtractionSchemaContextWrapper } from "../fabrk/hooks/ExtractionSchemaContext";
import { LeadAgentContextWrapper } from "../fabrk/hooks/LeadAgentContext";
import { MessageContextWrapper } from "../fabrk/hooks/MessageContext";
import { EventStreamingWrapper } from "../message/StreamMessageContext";

/*
These are globally available contexts for the application. If the context requires params passed in from the router they will be need to nested update the App component because it sets up the routes and params.
*/

function AppContextProvider({ children }: { children: React.ReactNode }) {
  return (
    <CompanyContextWrapper>
      <LeadAgentContextWrapper>
        <EventStreamingWrapper>
          <MessageContextWrapper>
            <ExtractionSchemaContextWrapper>
              {children}
            </ExtractionSchemaContextWrapper>
          </MessageContextWrapper>
        </EventStreamingWrapper>
      </LeadAgentContextWrapper>
    </CompanyContextWrapper>
  );
}

export default AppContextProvider;
