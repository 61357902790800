/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { UseMutateFunction, useMutation } from "react-query";
import {
  addAgentTool,
  getAgentAgentToolsByParent,
} from "../../../api/agentAgentTool.api";
import { AgentAgentToolRow } from "../../../api/agentAgentTool.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useAgentContext } from "./AgentContext";

export const AgentAgentToolContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setAlertProps } = useAlertContext();

  const { activeAgent } = useAgentContext();

  const { mutate: getAgentAgentTool, data: agentAgentTools } = useMutation(
    getAgentAgentToolsByParent,
  );

  const { mutate: addAgentAgentTool } = useMutation(addAgentTool, {
    onSuccess: async () => {
      getAgentAgentTool(activeAgent?.id as string);
      setAlertProps({
        message: "Agent tool added",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  function handleGetAgentAgentTools() {
    getAgentAgentTool(activeAgent?.id as string);
  }

  useEffect(() => {
    if (activeAgent) {
      handleGetAgentAgentTools();
    }
  }, [activeAgent]);

  const value = {
    agentAgentTools,
    addAgentAgentTool,
    handleGetAgentAgentTools,
  };

  return (
    <AgentAgentToolContext.Provider value={value}>
      {children}
    </AgentAgentToolContext.Provider>
  );
};

export const AgentAgentToolContext = createContext({
  agentAgentTools: [] as AgentAgentToolRow[] | undefined,
  addAgentAgentTool: {} as UseMutateFunction<
    AgentAgentToolRow[],
    unknown,
    {
      parentAgentId: string;
      childAgentId: string;
    },
    unknown
  >,
  handleGetAgentAgentTools: () => {},
});

export const useAgentAgentToolContext = () => useContext(AgentAgentToolContext);
