import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData } from "../api/apiHelpers";
import { LeadInsert, LeadWithRelations } from "../api/lead.type";

export function handleSignupUser({
  userId,
  companyId,
  leadId,
}: {
  userId: string;
  companyId: string;
  leadId: string;
}): Promise<LeadWithRelations> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.user + "/signup";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      companyId,
      leadId,
    }),
  };

  return fetchData<LeadWithRelations>(url, request);
}

export function updateLeadById({
  id,
  values,
}: {
  id: string;
  values: LeadInsert;
}): Promise<LeadWithRelations> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.client + "/fabrk/" + id;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...values,
    }),
  };

  return fetchData<LeadWithRelations>(url, request);
}

export function getLeadById(id: string): Promise<LeadWithRelations> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.client + "/fabrk/" + id;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<LeadWithRelations>(url, request);
}
