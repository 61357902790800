import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { LeadAgentRow } from "../api/leadAgent.type";

export function getLeadAgentsByLeadId(leadId: string): Promise<LeadAgentRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.leadAgent + "/lead/" + leadId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<LeadAgentRow[]>(url, request);
}

export function getLeadAgentsByCompany(companyId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.leadAgent +
    "/company/" +
    companyId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<LeadAgentRow[]>(url, request);
}

export function createLeadAgentWithExistingLead({
  agentId,
  leadId,
}: {
  agentId: string;
  leadId: string;
}): Promise<LeadAgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.leadAgent + "/add-lead";

  const request = {
    method: "POST",
    body: JSON.stringify({ agentId, leadId }),
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<LeadAgentRow>(url, request);
}

export function createLeadAgentWithNewLead({
  agentId,
}: {
  agentId: string;
}): Promise<LeadAgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.leadAgent + "/create";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ agentId }),
  });

  return fetchData<LeadAgentRow>(url, request);
}
