/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { ToolRow } from "../../../api/tool.type";
import TableWithSearch, {
  TableHeadItem,
} from "../../shared/table/TableWithSearch";
import { handlePageNav } from "../../shared/table/handlePagination";
import { handleFilterValue } from "../../shared/table/setTableHeadWithFilters";
import { useExtractionResponsesContext } from "./ExtractionResponsesContext";
import ExtractionResponsesList from "./ExtractionResponsesList";

const baseTableHead = [
  { label: "ID" },
  { label: "Created" },
  { label: "Type" },
  { label: "Question" },
  { label: "Answer" },
];

function ExtractionResponsesTable() {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const { extractionResponses } = useExtractionResponsesContext();

  const [filteredTools, setFilteredTools] = useState<Partial<ToolRow>[]>();

  const [tableHead] = useState<TableHeadItem[]>(baseTableHead);

  function baseFilter() {
    return (tool: Partial<ToolRow>, index: number) => index < 10;
  }

  const [setToolFilterFunction] =
    useState<(tool: Partial<ToolRow>, index: number) => boolean>(baseFilter);

  const rowCount =
    filteredTools && filteredTools.length > 0 ? filteredTools.length : null;

  return (
    <div className="max-w-full gap-4">
      {extractionResponses && extractionResponses.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          rowsPerPage={10}
          rowCount={rowCount}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              extractionResponses,
              setFilteredTools,
              setToolFilterFunction,
              baseFilter,
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setToolFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setToolFilterFunction,
              advancePage: false,
            })
          }
        >
          <ExtractionResponsesList />
        </TableWithSearch>
      )}
    </div>
  );
}

export default ExtractionResponsesTable;
