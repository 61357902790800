import { mapAgentAgentToolToTool } from "../../../api/agentAgentTool.type";
import { ExtractionResponsesContextWrapper } from "../../extraction/responses/ExtractionResponsesContext";
import { useAgentToolContext } from "../../fabrk/hooks/AgentToolContext";
import TabContainer from "../../shared/containers/Tabs";
import ToolTable from "../../tools/ToolTable";
import { useAgentAgentToolContext } from "../hooks/AgentAgentToolContext";
import { useAgentContext } from "../hooks/AgentContext";
import { EvalsContextWrapper } from "../hooks/EvalsContext";
import AgentApiDetailsContainer from "./AgentApiDetailsContainer";
import AgentEvalsContainer from "./AgentEvalsContainer";
import AgentResponsesContainer from "./AgentResponsesContainer";
import ThreadListContainer from "./AgentThreadListContainer";
import ViewAgentHeader from "./ViewAgentHeader";

const tabs = [
  { name: "Tools", href: "#tools", current: true },
  { name: "Threads", href: "#threads", current: false },
  { name: "Evaluations", href: "#evals", current: false },
  { name: "Extractions", href: "#evals", current: false },
  { name: "API", href: "#api", current: false },
];

export default function ViewAgent() {
  const { activeAgent } = useAgentContext();

  const { agentTools } = useAgentToolContext();

  const toolMap = agentTools?.map((at) => at.tool);

  const { agentAgentTools } = useAgentAgentToolContext();

  const aaTools = agentAgentTools?.map((aat) => mapAgentAgentToolToTool(aat));

  const combined = [...(toolMap || []), ...(aaTools || [])];

  return (
    <ExtractionResponsesContextWrapper>
      <EvalsContextWrapper>
        <div className="h-full min-h-screen bg-skin-fill py-12 sm:py-24">
          <ViewAgentHeader />
          <div className="mt-16 max-w-7xl px-6 lg:px-8">
            <TabContainer tabs={tabs}>
              {(activeTab: string) => (
                <>
                  {activeTab === "Tools" && <ToolTable tools={combined} />}
                  {activeTab === "Threads" && (
                    <ThreadListContainer leadAgents={activeAgent?.lead_agent} />
                  )}
                  {activeTab === "Evaluations" && <AgentEvalsContainer />}
                  {activeTab === "Extractions" && <AgentResponsesContainer />}
                  {activeTab === "API" && <AgentApiDetailsContainer />}
                </>
              )}
            </TabContainer>
          </div>
        </div>
      </EvalsContextWrapper>
    </ExtractionResponsesContextWrapper>
  );
}
