import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { AgentRow } from "../../api/agent.type";

function AgentToolItemFooter({ agent }: { agent: AgentRow }) {
  return (
    <div>
      <div className="-mt-px flex divide-x divide-skin-hover">
        <div className="-ml-px flex w-0 flex-1">
          <a
            href={`/agent/${agent.id}`}
            className={clsx(
              "relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-lg border border-transparent py-4 text-sm font-semibold hover:bg-skin-fill",
            )}
          >
            <PencilIcon aria-hidden="true" className="h-6 w-6 text-skin-base" />
          </a>
        </div>

        <div className="flex w-0 flex-1">
          <button
            onClick={() => {
              // removeAgent({
              //   toolId: foundAgent?.tool.id as string,
              //   agentId: activeLeadAgent?.agent_id as string,
              // });
            }}
            className={clsx(
              "relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-lg border border-transparent py-4 text-sm font-semibold hover:bg-skin-fill",
            )}
          >
            <>
              <TrashIcon className="h-6 w-6 text-red-300" />
            </>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AgentToolItemFooter;
