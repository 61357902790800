import { createContext, useContext, useEffect, useState } from "react";

export const AlertContextWrapper = (props: any) => {
  const [globalLoading, setGlobalLoading] = useState(false);

  const [alertProps, setAlertProps] = useState<{
    message: string;
    color: "red" | "green" | "yellow" | "orange";
  }>({
    message: "",
    color: "red",
  });

  useEffect(() => {
    if (alertProps.message) {
      const timeout = setTimeout(() => {
        setAlertProps({
          message: "",
          color: "red",
        });
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [alertProps]);

  const value = { alertProps, setAlertProps, globalLoading, setGlobalLoading };

  return (
    <AlertContext.Provider value={value}>
      {props.children}
    </AlertContext.Provider>
  );
};

export const AlertContext = createContext({
  alertProps: {
    message: "",
    color: "red",
  } as {
    message: string;
    color: "red" | "green" | "yellow" | "orange";
  },
  setAlertProps: (props: any) => {},
  globalLoading: false,
  setGlobalLoading: (loading: boolean) => {},
});

export const useAlertContext = () => useContext(AlertContext);
