import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  ApiKeyResponse,
  CompanyConfigRow,
  CompanyForPaths,
  CompanyRow,
  CompanyUpdate,
} from "../api/company.type";

export function getCompanies(): Promise<CompanyRow[]> {
  const url = process.env.REACT_APP_API_URL + "/company";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CompanyRow[]>(url, request);
}

export function getCompanyByUserId(userId: string): Promise<CompanyRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.company + "/user/" + userId;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CompanyRow[]>(url, request);
}

export function getCompaniesForPaths(): Promise<CompanyForPaths[]> {
  const url = process.env.REACT_APP_API_URL + "/company/public";

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CompanyForPaths[]>(url, request);
}

export function updateCompanyById(
  updateCompany: CompanyUpdate,
): Promise<CompanyRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.company +
    "/fabrk/" +
    updateCompany.id;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(updateCompany),
  };

  return fetchData<CompanyRow>(url, request);
}

export function createFabrkCompany({
  leadId,
  name,
}: {
  leadId: string;
  name: string;
}): Promise<CompanyRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.company + "/fabrk/create";

  const request = {
    method: "POST",
    body: JSON.stringify({ leadId, name }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<CompanyRow>(url, request);
}

export function getCompanyConfig(): Promise<CompanyConfigRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.companyConfig + "/";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<CompanyConfigRow>(url, request);
}

export function getApiKeyByCompanyId(): Promise<ApiKeyResponse> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.companyConfig + "/api-key";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ApiKeyResponse>(url, request);
}

export function createApiKey(): Promise<ApiKeyResponse> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.companyConfig +
    "/create-api-key";

  const request = setHeaderOptions({
    method: "POST",
  });

  return fetchData<ApiKeyResponse>(url, request);
}

export function updateCompanyConfig({
  id,
  values,
}: {
  id: string;
  values: Partial<CompanyConfigRow>;
}): Promise<CompanyConfigRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.companyConfig + "/" + id;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(values),
  });

  return fetchData<CompanyConfigRow>(url, request);
}

export function getCompanyById(id: string) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.company + "/" + id;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<CompanyRow>(url, request);
}
