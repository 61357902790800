import { MenuItem } from "@headlessui/react";
import { Cog6ToothIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { useCompanyContext } from "../fabrk/hooks/CompanyContext";
import { useDarkModeContext } from "../fabrk/layout/hooks/useDarkMode";
import DropdownMenu from "../shared/menu/DropdownMenu";

export default function SettingsMenu() {
  const { isDarkMode, toggleDarkMode } = useDarkModeContext();

  const { company } = useCompanyContext();

  return (
    <DropdownMenu
      MenuButtonChild={
        <div className="justify-left flex w-full gap-4 rounded-md bg-skin-fill px-3 py-2 text-left text-sm font-semibold text-skin-base hover:bg-skin-hover">
          <Cog6ToothIcon
            aria-hidden="true"
            className="h-6 w-6 shrink-0 text-skin-base group-hover:bg-skin-accent"
          />
          Settings
        </div>
      }
    >
      <div className="py-1">
        {company?.name && (
          <MenuItem>
            <a
              href={`/project/${company?.id}`}
              className="block px-4 py-2 text-sm text-skin-base/80 data-[focus]:bg-skin-hover data-[focus]:text-skin-base"
            >
              Project - {company?.name}
            </a>
          </MenuItem>
        )}
        <MenuItem>
          <button
            onClick={toggleDarkMode}
            className="group flex w-full items-center bg-skin-fill px-4 py-2 text-sm text-skin-base data-[focus]:bg-skin-hover data-[focus]:text-skin-base"
          >
            {isDarkMode ? (
              <MoonIcon className="mr-3 h-5 w-5 text-skin-base group-hover:bg-skin-hover" />
            ) : (
              <SunIcon
                aria-hidden="true"
                className="mr-3 h-5 w-5 text-skin-base group-hover:bg-skin-hover"
              />
            )}
            {isDarkMode ? "Dark" : "Light"} Mode
          </button>
        </MenuItem>
      </div>
    </DropdownMenu>
  );
}
