import { Tooltip } from "@material-tailwind/react";
import { useAgentContext } from "../hooks/AgentContext";
import { AgentRow } from "../../../api/agent.type";
import { ShareArrow } from "../../shared/icons/icons";
import HeaderMenu from "./HeaderMenu";
import { Dispatch, SetStateAction } from "react";

function AgentSettingsHeaderButtons({
  setDialogOpen,
  setDialogTitle,
  dialogOpen,
  activeAgent,
}: {
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setDialogTitle: Dispatch<SetStateAction<string>>;
  dialogOpen: boolean;
  activeAgent: AgentRow | undefined;
}) {
  const { isCompanyAgent, handleFork } = useAgentContext();

  return (
    <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
      <Tooltip
        placement="bottom"
        content={
          isCompanyAgent
            ? "This will change the visibility of this agent"
            : "Forking the agent will allow you to edit it"
        }
        className="bg-skin-hover text-skin-base"
      >
        <button
          onClick={() => {
            if (isCompanyAgent) {
              setDialogTitle("Share Agent");
              setDialogOpen(!dialogOpen);
            } else {
              handleFork();
            }
          }}
          type="button"
          className="inline-flex w-full flex-shrink-0 items-center justify-center gap-4 rounded-md bg-skin-accentSecondary px-3 py-2 text-sm font-semibold text-skin-hover shadow-sm hover:bg-skin-accent sm:flex-1"
        >
          <ShareArrow className="h-4 w-4" />
          {!isCompanyAgent
            ? "Fork"
            : activeAgent?.visibility === "public"
              ? "Change to private"
              : "Deploy Agent"}
        </button>
      </Tooltip>
      <div className="ml-3 inline-flex sm:ml-0">
        <HeaderMenu
          setDialogOpen={setDialogOpen}
          setDialogTitle={setDialogTitle}
        />
      </div>
    </div>
  );
}

export default AgentSettingsHeaderButtons;
