import { Menu, MenuButton, MenuItems } from "@headlessui/react";

export default function DropdownMenu({
  MenuButtonChild,
  children,
}: {
  MenuButtonChild: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <Menu as="div" className="relative z-50 overflow-visible">
      <MenuButton className="w-full">{MenuButtonChild}</MenuButton>

      <MenuItems
        transition
        className="absolute -top-24 left-0 z-50 mt-2 w-56 origin-top-right overflow-visible rounded-md border-2 border-skin-hover bg-skin-fill shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        {children}
      </MenuItems>
    </Menu>
  );
}
