import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import {
  Option,
  Popover,
  PopoverContent,
  PopoverHandler,
  Select,
} from "@material-tailwind/react";
import { useState } from "react";

import { FilterValue, TableHeadItem } from "./TableWithSearch";

export function ColumnFilter({
  head,
  handleFilterValue,
}: {
  head: TableHeadItem;
  handleFilterValue?: (value: FilterValue) => void;
}) {
  const [filterValue, setFilterValue] = useState<string | null>(null);

  return (
    <Popover>
      <PopoverHandler>
        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
      </PopoverHandler>
      <PopoverContent>
        <Select
          className="w-72"
          onChange={(value) => {
            if (!value) {
              return;
            }
            setFilterValue(value);
            handleFilterValue &&
              handleFilterValue({
                value,
                column: head.label,
              });
          }}
          value={filterValue || "All"}
          variant="standard"
          label="Filter"
        >
          {head.filterValues?.map((value) => {
            return (
              <Option key={value} value={value}>
                {value}
              </Option>
            );
          })}
        </Select>
      </PopoverContent>
    </Popover>
  );
}

export default ColumnFilter;
