import { Typography } from "@material-tailwind/react";
import { ExtractionResponseRow } from "../../../../api/extractionResponse.type";
import { SmsMetadataRow } from "../../../../api/smsMetadata.type";
import BaseChip from "../../../shared/chip/BaseChip";
import ToolCalledMetadataContainer from "./ToolCalledMetadataContainer";
import ReadableTextFormatter from "../../../shared/forms/FormatContext";

function MetadataItemList({
  content,
  systemMessages,
  functionMessages,
  toolMessages,
  extractionResponses,
}: {
  content: string;
  systemMessages?: SmsMetadataRow[];
  functionMessages?: SmsMetadataRow[];
  toolMessages?: SmsMetadataRow[];
  extractionResponses?: ExtractionResponseRow[];
}) {
  return (
    <div>
      {content === "context" && (
        <div className="min-w-md">
          {systemMessages?.map((msg, idx) => (
            <div key={idx}>
              <ReadableTextFormatter text={msg.content} />
            </div>
          ))}
        </div>
      )}
      {content === "available" && (
        <div className="min-w-md grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
          {functionMessages?.map((msg, idx) => (
            <div key={idx}>
              <BaseChip>{msg?.tool?.name || msg.function_call_name}</BaseChip>
            </div>
          ))}
        </div>
      )}
      {content === "called" && (
        <ToolCalledMetadataContainer toolMessages={toolMessages} />
      )}

      {content === "data" && (
        <div>
          <div className="min-w-md grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {extractionResponses?.map((msg, idx) => (
              <div key={idx}>
                <Typography>{msg?.question_name}</Typography>
                <Typography className="font-bold">
                  {msg.question_answer}
                </Typography>
              </div>
            ))}
          </div>

          {(!extractionResponses || extractionResponses?.length === 0) && (
            <Typography className="w-full">
              No data extracted. Add a data extraction tool to this agent to
              extract data.
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}

export default MetadataItemList;
