import { useEffect, useRef, useState } from "react";
import { ErrorHelperText } from "../ErrorHelperText";
import clsx from "clsx";

export type RegisterInputProps = {
  register: any;
  errors?: any;
  label?: string;
  name: string;
  required?: boolean;
  [key: string]: any;
};

// A utility function to format the input value as currency
function formatToCurrency(value: string) {
  // Remove all non-numeric characters except for a decimal point
  const numericValue = value.replace(/[^0-9.]/g, "");

  // Prevent more than one decimal point
  const parts = numericValue.split(".");
  const whole = parts[0];
  const decimal = parts[1] ? parts[1].slice(0, 2) : ""; // Limit to two decimal places

  // Format the whole part with commas for thousands
  const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the formatted value with optional decimal
  return decimal ? `${formattedWhole}.${decimal}` : formattedWhole;
}

const inputBaseClassName =
  "w-full rounded-md border-0 bg-skin-hover py-1 text-skin-base placeholder:text-skin-muted focus:border-2 focus:border-skin-accentSecondary focus:text-skin-base focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6 h-10";

export function CurrencyInputBase(
  props: {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    [x: string]: any;
  } & React.InputHTMLAttributes<HTMLInputElement>,
) {
  const { value, setValue, ...rest } = props;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Only format to currency if the value is numeric
    const formattedValue = formatToCurrency(inputValue);

    setValue(formattedValue);
  };

  return (
    <div className="relative rounded-md shadow-sm">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <span className="text-skin-base sm:text-sm">$</span>
      </div>
      <input
        type="text" // Set input type as text but restrict to numbers
        value={value}
        onChange={handleOnChange}
        {...rest}
        className={clsx(inputBaseClassName, "pl-7")} // Assuming you have a class for styling
      />
    </div>
  );
}

export function NumberInputBase(
  props: {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    [x: string]: any;
  } & React.InputHTMLAttributes<HTMLInputElement>,
) {
  const { value, setValue, ...rest } = props;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    // Only format to currency if the value is numeric
    const formattedValue = inputValue.replace(/[^0-9]/g, "");

    setValue(formattedValue);
  };

  return (
    <input
      type="text" // Set input type as text but restrict to numbers
      value={value}
      onChange={handleOnChange}
      {...rest}
      className={inputBaseClassName}
    />
  );
}

export function InputBase(
  props: {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    [x: string]: any;
  } & React.InputHTMLAttributes<HTMLInputElement>,
) {
  const { value, setValue, ...rest } = props;
  const handleOnChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setValue(event.target.value);
  };
  return (
    <div className="rounded-md shadow-sm">
      <input
        value={value}
        onChange={handleOnChange}
        {...rest}
        className={inputBaseClassName}
      />
    </div>
  );
}

export const FabrkInput = ({
  register,
  errors,
  label,
  name,
  required = true,
  ...props
}: RegisterInputProps) => {
  return (
    <>
      <input
        placeholder={label || ""}
        label={label || ""}
        {...(register ? register(name, { required }) : {})}
        className={inputBaseClassName}
        {...props}
      />
      {errors[name] && (
        <ErrorHelperText
          text={
            errors[name].message ||
            `${label} ${errors[name].type}` ||
            `${label} invalid`
          }
        />
      )}
    </>
  );
};

export function InputBaseWithIcon(props: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  children?: React.ReactNode;
  [x: string]: any;
}) {
  const { value, setValue, children, ...rest } = props;
  const handleOnChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setValue(event.target.value);
  };
  return (
    <div>
      <div className="relative mt-2 rounded-md shadow-sm">
        {children}
        <input
          value={value}
          onChange={handleOnChange}
          {...rest}
          className="block w-full rounded-md border-0 bg-skin-hover py-1.5 pl-10 text-skin-base placeholder:text-skin-muted focus:border-2 focus:border-skin-accentSecondary focus:text-skin-base focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
}

export function TextArea(props: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit?: (e: any) => void;
  [x: string]: any;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { value, setValue, handleSubmit, ...rest } = props;
  const [rows, setRows] = useState(2); // Initial rows set to 3

  useEffect(() => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.rows = 1; // Reset rows to 1 to get the correct scrollHeight
      const scrollHeight = textarea.scrollHeight; // Get the content height
      const lineHeight = parseInt(
        window.getComputedStyle(textarea).lineHeight,
        10,
      ); // Get the line height
      const newRows = Math.max(2, Math.ceil(scrollHeight / lineHeight)); // Calculate rows based on height and ensure a minimum of 2 rows

      setRows(newRows); // Update rows state
    }
  }, []);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      {...rest}
      rows={rows}
      className="sm:text-md block w-full resize-none rounded-none rounded-l-md border-0 bg-skin-hover p-1.5 text-skin-base shadow-sm outline-none placeholder:text-skin-muted focus:text-skin-base focus:ring-0"
      onKeyDown={(e) => {
        if (e.key === "Enter" && handleSubmit) {
          e.preventDefault();
          handleSubmit(e as any);
        }
      }}
    />
  );
}

export function MainTextInput(props: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit?: (e: any) => void;
  [x: string]: any;
}) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { value, setValue, handleSubmit, ...rest } = props;
  const [rows] = useState(2);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      {...rest}
      rows={rows}
      className="sm:text-md block w-full resize-none rounded-none rounded-l-md border-0 bg-skin-hover p-1.5 text-skin-base shadow-sm outline-none placeholder:text-skin-muted focus:text-skin-base focus:ring-0"
      onKeyDown={(e) => {
        if (e.key === "Enter" && handleSubmit) {
          e.preventDefault();
          handleSubmit(e as any);
        }
      }}
    />
  );
}
