import { TrashIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { snakeCaseToTitleCase } from "../../../utils/stringManipulation";
import { EditIcon } from "../../shared/icons/icons";
import { ParametersRowPropsWithEdit } from "./ExtractionParametersRowContainer";
import { useExtractionParametersTableContext } from "./ExtractionParametersTableContext";

export function ExtractionParametersViewRow({
  item,
  index,
  isEdit,
  setIsEdit,
}: ParametersRowPropsWithEdit) {
  const { remove } = useExtractionParametersTableContext();

  const { id, question, type, required, questionId } = item;

  return (
    <tr key={id} className="even:bg-blue-gray-50/50">
      <td>
        <div className="flex flex-col">
          <Typography variant="small" color="blue-gray" className="font-normal">
            {questionId}
          </Typography>
        </div>
      </td>

      <td>
        <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal capitalize"
          >
            {snakeCaseToTitleCase(type)}
          </Typography>
        </div>
      </td>

      <td>
        <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal capitalize"
          >
            {question}
          </Typography>
        </div>
      </td>

      <td>
        <div className="flex flex-col">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal capitalize"
          >
            {required ? "Yes" : "No"}
          </Typography>
        </div>
      </td>
      <td>
        <div className="flex flex-col">
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsEdit(!isEdit);
            }}
          >
            <div className="flex flex-row items-center gap-2">
              <EditIcon className="h-4 w-4" />
            </div>
          </button>
        </div>
      </td>
      <td>
        <div className="flex flex-col">
          <button
            onClick={(e) => {
              e.preventDefault();
              remove(index);
            }}
          >
            <div className="flex flex-row items-center gap-2">
              <TrashIcon className="h-4 w-4 text-red-400" />
            </div>
          </button>
        </div>
      </td>
    </tr>
  );
}
