/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  createNewAgent,
  forkAgent,
  getAgentById,
  updateAgentApiCall,
} from "../../../api/agent.api";
import {
  AgentType,
  AgentInsert,
  AgentRow,
  AgentUpdate,
} from "../../../api/agent.type";
import { LeadAgentRow } from "../../../api/leadAgent.type";
import { updateSystemPrompt } from "../../../api/prompt.api";
import { PromptRow, PromptUpdate } from "../../../api/prompt.type";
import { useSessionContext } from "../../fabrk/hooks/FabrkSessionContext";
import { useLeadContext } from "../../fabrk/hooks/LeadContext";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useSession } from "@supabase/auth-helpers-react";

export const AgentContextWrapper = (props: any) => {
  const { setAlertProps, setGlobalLoading } = useAlertContext();

  const navigate = useNavigate();

  const { companyId } = useSessionContext();

  const queryClient = useQueryClient();

  const session = useSession();

  const { agentId } = useParams();
  const { lead } = useLeadContext();

  const {
    mutate: getAgent,
    data: activeAgent,
    isLoading,
  } = useMutation(getAgentById);

  const { mutate: updateAgent } = useMutation(updateAgentApiCall, {
    onSuccess: async (res) => {
      getAgent({
        id: res.id,
      });

      setAlertProps({
        message: "Agent updated",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: updatePrompt } = useMutation(updateSystemPrompt, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("leadAgents");
      getAgent({
        id: agentId as string,
      });

      setAlertProps({
        message: "Instructions updated",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: createAgent } = useMutation(createNewAgent, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("leadAgents");
      queryClient.invalidateQueries("companyAgents");

      navigate(`/a/${res.agent_id}/t/${res.id}`);
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: fork, isLoading: forkLoading } = useMutation(forkAgent, {
    onSuccess: async (res) => {
      setAlertProps({
        message: "Agent forked",
        color: "green",
      });
      queryClient.invalidateQueries("leadAgents");
      navigate(`/a/${res.id}`);
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  function handleFork() {
    fork({
      agentId: activeAgent?.id as string,
      companyId,
      leadId: lead?.id as string,
    });
  }

  function handleCreateNewAgentWithDefaults(type?: AgentType) {
    createAgent({
      company_id: companyId,
      lead_id: lead?.id,
      ...(type && { type }),
    });
  }

  // This checks if the active agent belongs to the company/user. If it doesn't the UI will not allow for agent updates/etc.
  const isCompanyAgent = activeAgent?.company_id === companyId;

  // If there is a session (user is logged in), you don't need to pass the leadId.
  useEffect(() => {
    if (agentId) {
      if (session) {
        getAgent({
          id: agentId,
        });
      } else {
        if (lead) {
          getAgent({
            id: agentId,
            leadId: lead.id,
          });
        }
      }
    }
  }, [agentId, session, lead]);

  useEffect(() => {
    setGlobalLoading(isLoading || forkLoading);
  }, [isLoading, forkLoading]);

  const value = {
    updateAgent,
    getAgent,
    activeAgent,
    loading: isLoading,
    updatePrompt,
    handleCreateNewAgentWithDefaults,
    createAgent,
    isCompanyAgent,
    fork,
    handleFork,
  };

  return (
    <AgentContext.Provider value={value}>
      {props.children}
    </AgentContext.Provider>
  );
};

export const AgentContext = createContext({
  updatePrompt: {} as UseMutateFunction<
    PromptRow,
    Error,
    Partial<PromptUpdate>,
    unknown
  >,
  updateAgent: {} as UseMutateFunction<
    AgentRow,
    Error,
    Partial<AgentUpdate>,
    unknown
  >,
  getAgent: {} as UseMutateFunction<
    AgentRow,
    unknown,
    {
      id: string;
      leadId?: string;
    },
    unknown
  >,
  activeAgent: {} as AgentRow | undefined,
  handleCreateNewAgentWithDefaults: (type?: AgentType) => {},
  loading: false,
  createAgent: {} as UseMutateFunction<
    LeadAgentRow,
    Error,
    Partial<AgentInsert> | null,
    unknown
  >,
  isCompanyAgent: false,
  fork: {} as UseMutateFunction<
    AgentRow,
    Error,
    {
      agentId: string;
      companyId: string;
      leadId: string;
    },
    unknown
  >,
  handleFork: () => {},
});

export const useAgentContext = () => useContext(AgentContext);
