import { useState } from "react";
import { LeadAgentRow } from "../../../api/leadAgent.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../../utils/parseTimeStamp";
import BaseChip from "../../shared/chip/BaseChip";

function ThreadListContainer({
  leadAgents,
}: {
  leadAgents: LeadAgentRow[] | undefined;
}) {
  const sortedThreads = leadAgents?.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });
  const [visibleCount, setVisibleCount] = useState(20);

  const showMoreAmount = 20;

  // Function to show more items
  const showMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + showMoreAmount);
  };

  const visibleThreads = sortedThreads?.slice(0, visibleCount);

  return (
    <div>
      {leadAgents && leadAgents?.length > 0 ? (
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-3">
          {visibleThreads?.map((lc) => {
            const fullName = lc.lead?.first_name
              ? `${lc.lead?.first_name} ${lc.lead?.last_name}`
              : "";

            return (
              <a key={lc.id} href={`/a/${lc.agent_id}/t/${lc.id}`}>
                <BaseChip>
                  {formatTimeStampToHumanReadableShortDateTime(lc.created_at)}{" "}
                  {fullName ? `- ${fullName}` : ""}
                </BaseChip>
              </a>
            );
          })}
        </div>
      ) : (
        <p>No tools available for this agent.</p>
      )}
      {leadAgents && visibleCount < leadAgents?.length && (
        <button
          onClick={showMoreItems}
          className="ml-3 mt-2 text-xs text-skin-accent hover:underline"
        >
          Show {showMoreAmount} More
        </button>
      )}
    </div>
  );
}

export default ThreadListContainer;
