import LayoutContainer from "../../components/layout/LayoutContainer";
import ProjectOverview from "../../components/project/ProjectOverview";
import { PageHeader } from "../../components/shared/containers/Containers";
import TabContainer from "../../components/shared/containers/Tabs";
import { mixpanelTrackEvent } from "../../utils/mixpanel";
import ApiKeysContainer from "./ApiKeysContainer";
import BillingContainer from "./BillingContainer";

const tabs = [
  { name: "Overview", href: "#overview", current: true },
  { name: "API", href: "#api-keys", current: false },
  // { name: "Team Members", href: "#team", current: false },
  { name: "Billing", href: "#billing", current: false },
];

function ViewProjectContainer() {
  mixpanelTrackEvent("View Project Settings");
  return (
    <LayoutContainer>
      <div className="ml-10 h-full min-h-screen bg-skin-fill py-4 md:py-10">
        <PageHeader title="Project Settings" />

        <TabContainer tabs={tabs}>
          {(activeTab: string) => (
            <>
              {activeTab === "Overview" && <ProjectOverview />}
              {activeTab === "API" && <ApiKeysContainer />}
              {/* {activeTab === "Billing" && <div>Team Members content</div>} */}
              {activeTab === "Billing" && <BillingContainer />}
            </>
          )}
        </TabContainer>
      </div>
    </LayoutContainer>
  );
}

export default ViewProjectContainer;
