import { AgentToolRow, AgentToolRowUpdate } from "./agentTool.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";

export function getCampaignToolByCampaign(
  agentId: string,
): Promise<AgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/fabrk/agent/" +
    agentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<AgentToolRow[]>(url, request);
}

export function addAgentTool({
  agentId,
  toolId,
}: {
  agentId: string;
  toolId: string;
}): Promise<AgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/agent/" +
    agentId +
    "/tool/" +
    toolId;

  const request = { method: "PUT" };

  return fetchData<AgentToolRow[]>(url, request);
}

export function updateAiCampaignFunction({
  agentIdFunctionId,
  values,
}: {
  agentIdFunctionId: string;
  values: AgentToolRowUpdate;
}): Promise<AgentToolRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/" +
    agentIdFunctionId;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<AgentToolRow>(url, request);
}

export function deleteAiCampaignFunction({
  agentId,
  toolId,
}: {
  agentId: string;
  toolId: string;
}): Promise<AgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/agent/" +
    agentId +
    "/tool/" +
    toolId;

  const request = { method: "DELETE" };

  return fetchData<AgentToolRow[]>(url, request);
}
