export function TableListContainer({
  key,
  children,
}: {
  key: string;
  children: React.ReactNode;
}) {
  return (
    <tr key={key} className="even:bg-skin-hover/80">
      {children}
    </tr>
  );
}

export function TableRowBase({ children }: { children: React.ReactNode }) {
  return (
    <td className="p-4">
      <div className="flex flex-col">{children}</div>
    </td>
  );
}
