import { HeadingBase } from "../shared/typography/TypographyBase";

const rocketEmoji = String.fromCodePoint(0x1f680);

function SignupSuccess() {
  return (
    <div className="mx-auto flex h-full min-h-screen flex-row justify-center bg-skin-fill py-24 sm:py-32">
      <div className="items center min-w-lg flex h-fit flex-col gap-8 rounded-lg border-2 border-skin-hover/80 p-4 shadow-md">
        <HeadingBase>Signup Success!</HeadingBase>

        <div className="flex flex-col gap-4 text-skin-base">
          <span>
            {" "}
            Fabrk AI is excited to announce its beta launch! {rocketEmoji}
          </span>
          <span>Want a personal demo? </span>
          <a
            target="_blank"
            href="https://calendly.com/kevin-fabrk/fabrk-ai-demo"
            rel="noreferrer"
            className="w-fit rounded-lg border-2 border-skin-accentSecondary p-3 font-bold text-skin-accentSecondary hover:border-skin-accent hover:bg-skin-accent hover:text-skin-base"
          >
            Book a demo
          </a>
        </div>
      </div>
    </div>
  );
}

export default SignupSuccess;
