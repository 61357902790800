import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Typography,
} from "@material-tailwind/react";
import { useState } from "react";
import {
  switchPort,
  toLowerCaseAndReplaceSpacesWithHyphens,
} from "../../../../utils/stringManipulation";
import { useAgentContext } from "../../../agent/hooks/AgentContext";
import { CopyToClipboardIconButton } from "../../../shared/forms/CopyToClipboard";

function Icon({ id, open }: { id: number; open: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="gray"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

const style = `
      .responsive-iframe {
        width: 80%; 
      }
      @media (min-width: 600px) {
        .responsive-iframe {
          width: 600px; 
        }
      }
      @media (min-width: 800px) {
        .responsive-iframe {
          width: 800px; 
        }
      }
    `;

const scriptTab = (src: string, width: string, name: string) => ` <script>
const iframe = document.createElement('iframe');

  const style = document.createElement('style');
  style.innerHTML = \`${style}\`;

iframe.src = '${src}'; 
iframe.title = '${name}';

const startingHeight = 250;

iframe.style.position = 'fixed';
iframe.style.bottom = '0';
iframe.style.left = '50%';
iframe.style.width = '100%';
iframe.style.height = startingHeight + "px";
iframe.style.border = 'none';
iframe.style.zIndex = '1000'; 
iframe.style.transform = 'translateX(-50%)';

iframe.classList.add('responsive-iframe');


window.addEventListener("message", (event) => {
     if (event.data.height) {
        const iframe = document.querySelector("iframe");
        iframe.style.height = event.data.height + "px";
    }
      if (event.data.height === 0) {
      const iframe = document.querySelector("iframe");
        iframe.style.height = startingHeight + "px";
    }

}, false);

document.body.appendChild(iframe);
</script>`;

function EmbedLinks() {
  const { activeAgent } = useAgentContext();
  const location = window.location.href;
  const oppositeUrl = location?.includes("localhost")
    ? switchPort(location)
    : "https://input.fabrk.ai/";

  const agentUrl = `${oppositeUrl}c/${
    activeAgent?.company?.slug
  }/${toLowerCaseAndReplaceSpacesWithHyphens(activeAgent?.name)}`;

  const [show, setShow] = useState(false);
  const tag = scriptTab(agentUrl, "600", activeAgent?.name as string);
  return (
    <div>
      <Typography>Link to agent page:</Typography>
      <div className="flex flex-row gap-1">
        <a className="text-skin-accentSecondary" href={agentUrl}>
          {agentUrl}
        </a>
        <CopyToClipboardIconButton text={agentUrl} />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex flex-row gap-1">
          <Accordion open={show} icon={<Icon id={1} open={show} />}>
            <AccordionHeader onClick={() => setShow(!show)}>
              <div className="flex flex-row gap-2">
                <Typography>Embed script:</Typography>
              </div>
            </AccordionHeader>

            <AccordionBody className="flex flex-col gap-1 rounded-sm bg-skin-hover p-2">
              <Typography>{tag}</Typography>
            </AccordionBody>
          </Accordion>
          <CopyToClipboardIconButton text={tag} />
        </div>
      </div>
    </div>
  );
}

export default EmbedLinks;
