import { AgentContextWrapper } from "../../components/agent/hooks/AgentContext";
import ViewAgent from "../../components/agent/view/ViewAgent";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { mixpanelTrackEvent } from "../../utils/mixpanel";

function ViewAgentContainer() {
  mixpanelTrackEvent("View Agent");
  return (
    <AgentContextWrapper>
      <LayoutContainer>
        <ViewAgent />
      </LayoutContainer>
    </AgentContextWrapper>
  );
}

export default ViewAgentContainer;
