import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import { MessageRow } from "../../../api/message.type";
import { formatTimeStampToHumanReadableDateTimeSeconds } from "../../../utils/parseTimeStamp";
import { List } from "../../shared/icons/icons";
import { MessageMetadataContextWrapper } from "./hooks/MessageMetadataContext";
import MessageMetadataContainer from "./metadata/MessageMetadataContainer";

function AssistantMessageChatItem({
  message,
  showMetadata,
  userMessageId,
}: {
  message: MessageRow;
  showMetadata?: boolean;
  userMessageId?: string;
}) {
  const [showMessageMetadata, setShowMessageMetadata] = useState(false);

  useEffect(() => {
    setShowMessageMetadata(showMetadata || false);
  }, [showMetadata]);

  return (
    <div className="flex w-full flex-col justify-between gap-4">
      <div className="flex flex-row items-center justify-between p-2 text-skin-base">
        <div>
          <Markdown rehypePlugins={[rehypeHighlight, remarkGfm]}>
            {message.content}
          </Markdown>
          {message.created_at && (
            <Typography className="text-xs text-skin-base">
              {formatTimeStampToHumanReadableDateTimeSeconds(
                message.created_at,
              )}
            </Typography>
          )}
        </div>
        <button
          className="justify-center rounded-md p-1 text-sm font-semibold hover:bg-skin-hover"
          onClick={() => setShowMessageMetadata(!showMessageMetadata)}
        >
          <List aria-hidden="true" className="h-4 w-4 text-skin-muted" />
        </button>
      </div>
      <MessageMetadataContextWrapper
        assistantMessageId={message.id}
        userMessageId={userMessageId as string}
      >
        <MessageMetadataContainer showMetadata={showMessageMetadata} />
      </MessageMetadataContextWrapper>
    </div>
  );
}

export default AssistantMessageChatItem;
