/* eslint-disable eqeqeq */
import { Fragment } from "react";

import { useExtractionResponsesContext } from "./ExtractionResponsesContext";
import { ExtractionResponsesViewRow } from "./ExtractionResponsesViewRow";

function ExtractionResponsesList() {
  const { extractionResponses } = useExtractionResponsesContext();
  return (
    <Fragment>
      {extractionResponses &&
        extractionResponses.map((item, index) => {
          return <ExtractionResponsesViewRow key={index} item={item} />;
        })}
    </Fragment>
  );
}

export default ExtractionResponsesList;
