/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgentType } from "../../../api/agent.type";
import { useLeadAgentContext } from "../../fabrk/hooks/LeadAgentContext";
import ShareAgentForm from "../../fabrk/layout/threadMenu/ShareAgentsForm";
import CloseButton from "../../menu/CloseButton";
import GenericDialog from "../../shared/dialog/GenericDialog";
import { useAgentContext } from "../hooks/AgentContext";
import { useLoadCompanyAgents } from "../hooks/useCompanyAgents";
import AgentSettingsHeaderButtons from "./AgentSettingsHeaderButtons";
import RunPersonaList from "./RunPersonaList";

function AgentSettingsHeader({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();

  const { companyAgents } = useLoadCompanyAgents();

  const personaAgents = companyAgents?.filter(
    (agent) => agent.type === AgentType.personas,
  );

  const { activeAgent } = useAgentContext();

  const { newLeadAgent, setActiveLeadAgent } = useLeadAgentContext();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogTitle, setDialogTitle] = useState("");

  useEffect(() => {
    if (newLeadAgent) {
      navigate(`/a/${newLeadAgent?.agent_id}/t/${newLeadAgent.id}`);
      setActiveLeadAgent(newLeadAgent);
    }
  }, [newLeadAgent]);

  return (
    <div>
      <div className="pb-1 sm:pb-6">
        <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
          <div className="sm:flex-1">
            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center">
                <a href={`/agent/${activeAgent?.id}`}>
                  <h3 className="text-xl font-bold capitalize text-skin-base sm:text-2xl">
                    {activeAgent?.name}
                  </h3>
                </a>
              </div>
              <CloseButton setOpen={setOpen} />
            </div>

            <AgentSettingsHeaderButtons
              setDialogOpen={setDialogOpen}
              setDialogTitle={setDialogTitle}
              dialogOpen={dialogOpen}
              activeAgent={activeAgent}
            />
          </div>
        </div>
      </div>
      <GenericDialog
        title={dialogTitle}
        open={dialogOpen}
        setOpen={setDialogOpen}
      >
        {dialogTitle === "Share Agent" && (
          <ShareAgentForm setDialogOpen={setDialogOpen} />
        )}
        {dialogTitle === "Run Persona" && personaAgents && (
          <RunPersonaList
            personas={personaAgents}
            setDialogOpen={setDialogOpen}
          />
        )}
      </GenericDialog>
    </div>
  );
}

export default AgentSettingsHeader;
