/* eslint-disable react-hooks/exhaustive-deps */
import { LinkIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import { useEffect, useMemo, useState } from "react";
import FabrkMenuDivider from "../../fabrk/layout/FabrkMenuDivider";
import IconButton from "../../shared/buttons/IconButton";
import SubmitButton from "../../shared/buttons/SubmitButton";
import { TextArea } from "../../shared/forms/input/InputBase";
import ShowMoreParagraph from "../../shared/typography/ShowMoreParagraph";
import { HeadingBase, LabelBase } from "../../shared/typography/TypographyBase";
import { useAgentContext } from "../hooks/AgentContext";
import { useEvalsToolContext } from "../hooks/EvalsContext";
import { useLeadAgentContext } from "../../fabrk/hooks/LeadAgentContext";

function AgentContextAndEvals() {
  const [isEditing, setIsEditing] = useState(false);

  const { activeAgent, updatePrompt, isCompanyAgent } = useAgentContext();

  const { activeLeadAgent } = useLeadAgentContext();

  const [instructions, setInstructions] = useState(
    activeAgent?.system_prompt?.text || "",
  );

  const [evalsLoading, setEvalsLoading] = useState(false);

  const {
    hasEvalAgent,
    evalParamLink,
    enableEvals,
    runEvals,
    runEvalsLoading,
  } = useEvalsToolContext();

  useMemo(() => {
    if (activeAgent) {
      setInstructions(activeAgent.system_prompt?.text || "");
    }
  }, [activeAgent]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (instructions !== activeAgent?.system_prompt?.text) {
      updatePrompt({
        id: activeAgent?.system_prompt?.id as string,
        text: instructions,
      });
    }

    setIsEditing(false);
  };

  useEffect(() => {
    if (runEvalsLoading) {
      setEvalsLoading(true);
    }
  }, [runEvalsLoading]);

  useEffect(() => {
    if (evalsLoading) {
      setTimeout(() => {
        setEvalsLoading(false);
      }, 20000);
    }
  }, [evalsLoading]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <HeadingBase>Context and Evaluations</HeadingBase>
        {isCompanyAgent && (
          <IconButton
            onClick={() => {
              setIsEditing(!isEditing);
              setInstructions(activeAgent?.system_prompt?.text || "");
            }}
          >
            <PencilIcon className="h-4 w-4" />
          </IconButton>
        )}
      </div>
      <FabrkMenuDivider />
      <form onSubmit={handleSubmit}>
        <div className="mt-4 flex w-full flex-col gap-4">
          <div>
            <Tooltip
              placement="top"
              content="Instructions are the text that the AI model will use to generate responses. You can edit this text to change the behavior of the AI model."
              className="max-w-md bg-skin-hover text-skin-base"
            >
              <div className="flex flex-row gap-2">
                <LabelBase>Instructions</LabelBase>
                <LabelBase>
                  Version: {activeAgent?.system_prompt?.version}
                </LabelBase>
              </div>
            </Tooltip>
            {isEditing ? (
              <TextArea value={instructions} setValue={setInstructions} />
            ) : (
              <ShowMoreParagraph text={instructions} />
            )}
          </div>

          <div>
            {!hasEvalAgent && (
              <SubmitButton
                onClick={(e) => {
                  e.preventDefault();
                  enableEvals(activeAgent?.id as string);
                }}
              >
                Enable Evals
              </SubmitButton>
            )}
          </div>
          <div>
            {evalParamLink && (
              <a href={`/tool/${evalParamLink}`}>
                <div className="flex flex-row items-center gap-2">
                  Link to evaluation parameters
                  <LinkIcon className="h-4 w-4" />
                </div>
              </a>
            )}
          </div>

          <div>
            {evalParamLink && (
              <a href={`/agent/${activeAgent?.id}#evals`}>
                <div className="flex flex-row items-center gap-2">
                  Link to evaluation responses
                  <LinkIcon className="h-4 w-4" />
                </div>
              </a>
            )}
          </div>

          <div>
            {hasEvalAgent && activeLeadAgent && !isEditing && (
              <SubmitButton
                loading={evalsLoading}
                onClick={(e) => {
                  e.preventDefault();
                  runEvals(activeLeadAgent?.id as string);
                }}
              >
                {evalsLoading
                  ? "Evaluations Running..."
                  : "Run Evaluations on this Thread"}
              </SubmitButton>
            )}
          </div>

          {isEditing && (
            <div className="flex justify-end">
              <div>
                <SubmitButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={instructions === activeAgent?.system_prompt?.text}
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default AgentContextAndEvals;
