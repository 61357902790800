import { useState } from "react";

const rocketEmoji = String.fromCodePoint(0x1f680);

function NotificationBannerContainer() {
  const [isVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="fixed left-1/2 top-4 z-50 w-1/2 -translate-x-1/2 rounded-md bg-skin-accent px-4 py-2 text-center text-skin-inverse shadow-md">
      <div className="flex flex-row items-center justify-center gap-4 text-skin-base">
        <span>
          {" "}
          Fabrk AI is excited to announce its beta launch! {rocketEmoji}
        </span>
        <span>Want a personal demo? </span>
        <a
          target="_blank"
          href="https://calendly.com/kevin-fabrk/fabrk-ai-demo"
          rel="noreferrer"
          className="w-fit rounded-lg border-2 border-skin-accentSecondary p-1 font-bold text-skin-accentSecondary hover:border-skin-accent hover:bg-skin-accentSecondary hover:text-skin-base"
        >
          Book a demo
        </a>
      </div>
      {/* <button
        onClick={() => setIsVisible(false)}
        className="ml-4 text-skin-inverse/80 hover:underline"
      >
        Dismiss
      </button> */}
    </div>
  );
}

export default NotificationBannerContainer;
