import { Tooltip } from "@material-tailwind/react";
import { useState } from "react";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";
import FabrkMenuDivider from "../fabrk/layout/FabrkMenuDivider";
import IconButton from "../shared/buttons/IconButton";
import SubmitButton from "../shared/buttons/SubmitButton";
import { EditIcon } from "../shared/icons/icons";
import EditToolName from "./EditToolName";
import { useToolContext } from "./hooks/ToolContext";

function ViewToolHeaderDetails() {
  const [isEdit, setIsEdit] = useState(false);
  const { tool, isCompanyTool, handleForkTool } = useToolContext();

  return (
    <div className="col-span-2 flex flex-col gap-8">
      <div className="w-full leading-7 text-skin-base/80">
        <div className="flex flex-row justify-between">
          <p className="font-semibold leading-7 text-skin-muted/80">
            Tool Details
          </p>
          {isCompanyTool && (
            <IconButton
              onClick={() => {
                setIsEdit(!isEdit);
              }}
            >
              <EditIcon className="h-5 w-5" />
            </IconButton>
          )}
        </div>
        <FabrkMenuDivider />
        {isEdit ? (
          <EditToolName setIsEdit={setIsEdit} />
        ) : (
          <div>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-skin-base sm:text-4xl">
              {tool?.name}
            </h1>
            <div className="max-w-xl">
              <p className="mt-6">
                Type: {snakeCaseToTitleCase(tool?.type as string)}
              </p>
            </div>
            <div className="max-w-xl">
              <p className="mt-6">{tool?.description}</p>
            </div>
          </div>
        )}
      </div>
      <dl className="grid grid-cols-2 gap-8 sm:grid-cols-2">
        <div>
          <dt className="text-sm font-semibold leading-6 text-skin-base">
            Agents using this tool
          </dt>
          <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-skin-base">
            {tool?.agent_tool?.length || 0}
          </dd>
        </div>
        <div className="mt-10 flex flex-col gap-2">
          {tool?.agent_tool?.map((agentTool) => {
            return (
              <a href={`/a/${agentTool?.agent_id}`} key={agentTool.id}>
                <div className="flex w-fit flex-row items-center justify-evenly gap-1 rounded-md bg-skin-hover p-2 text-skin-base">
                  <p className="text-xs">{agentTool?.agent?.name}</p>
                </div>
              </a>
            );
          })}
        </div>
      </dl>

      {!isCompanyTool && (
        <Tooltip
          placement="bottom"
          content="Fork this tool to make edits."
          className="bg-skin-hover text-skin-base"
        >
          <div>
            <SubmitButton onClick={() => handleForkTool(tool?.id as string)}>
              Fork Tool
            </SubmitButton>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default ViewToolHeaderDetails;
