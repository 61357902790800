import { CompanyConfigRow, CompanyRow } from "./company.type";
import { FontRow } from "./font.type";
import { SmsContentRow } from "./smsContent.type";
import { Visibility } from "./tool.type";

import { PromptRow } from "./prompt.type";
import { TestContextRow } from "./testContext.type";
import { LeadAgentRow } from "./leadAgent.type";
import { AgentToolRow } from "./agentTool.type";

export type DeliveryMethods = "sms" | "email" | "email_sms";

export type AgentFork = {
  id: string;
  child_agent_id?: string;
  parent_agent_id?: string;
};

export type AgentRow = {
  default?: boolean;
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  goal: string;
  company?: CompanyRow;
  system_prompt?: PromptRow;
  system_prompt_id?: string;
  sms_content?: SmsContentRow[];
  company_id?: string;
  tag_line?: string;
  title?: string;
  step_one_text?: string;
  klaviyo_list_id?: string;
  reimbursement_criteria?: string;
  company_config?: CompanyConfigRow[];
  hero_image_url?: string;
  status: string;
  follow_up_disabled?: boolean;
  title_font?: FontRow;
  paragraph_font?: FontRow;
  subtitle_font?: FontRow;
  title_font_weight?: number;
  paragraph_font_weight?: number;
  type?: AgentType;
  multiple_company_reimbursements_allowed: boolean;
  subtitle_font_weight?: number;
  shopify_default?: boolean;
  shopify_abandoned_cart?: boolean;
  delivery_method?: DeliveryMethods;
  test_context?: TestContextRow[];
  fabrk_public?: boolean;
  agent_fork?: AgentFork[];
  lead_agent?: LeadAgentRow[];
  agent_tool?: AgentToolRow[];
  visibility: Visibility;
  image_path?: string;
};

export type AgentInsert = {
  lead_id: string;
  company_id: string;
  type?: AgentType;
  name?: string;
};

export type AgentUpdate = Partial<AgentInsert> & {
  id: string;
  name?: string;
  sms_content?: Partial<SmsContentRow>;
  created_at?: string;
  system_prompt?: PromptRow;
  system_prompt_id?: string;
  tag_line?: string;
  title?: string;
  step_one_text?: string;
  klaviyo_list_id?: string;
  company_id?: string;
  status?: string;
  follow_up_disabled?: boolean;
  reimbursement_criteria?: string;
  hero_image_url?: string;
  shopify_default?: boolean;
  shopify_abandoned_cart?: boolean;
  delivery_method?: DeliveryMethods;
  fabrk_public?: boolean;
  goal?: string;
  visibility?: Visibility;
};

export type AgentForPaths = {
  params: {
    Agent: string;
  };
};

export enum AgentType {
  personas = "personas",
  businessIntelligence = "businessIntelligence",
  commerce = "commerce",
  communication = "communication",
  contentAndFiles = "contentAndFiles",
  humanResources = "humanResources",
  itOperations = "itOperations",
  lifestyleAndEntertainment = "lifestyleAndEntertainment",
  marketing = "marketing",
  productivity = "productivity",
  salesAndCRM = "salesAndCRM",
  support = "support",
  websiteAndAppBuilding = "websiteAndAppBuilding",
  evaluation = "evaluation",
}

export type AgentTypeWithLabel = {
  value: AgentType;
  label: string;
};

export const AgentTypes = [
  {
    value: AgentType.personas,
    label: "Personas",
  },
  {
    value: AgentType.businessIntelligence,
    label: "Business Intelligence",
  },
  {
    value: AgentType.commerce,
    label: "Commerce",
  },
  {
    value: AgentType.communication,
    label: "Communication",
  },
  {
    value: AgentType.contentAndFiles,
    label: "Content & Files",
  },
  {
    value: AgentType.humanResources,
    label: "Human Resources",
  },
  {
    value: AgentType.itOperations,
    label: "IT Operations",
  },
  {
    value: AgentType.lifestyleAndEntertainment,
    label: "Lifestyle & Entertainment",
  },
  {
    value: AgentType.marketing,
    label: "Marketing",
  },
  {
    value: AgentType.productivity,
    label: "Productivity",
  },
  {
    value: AgentType.salesAndCRM,
    label: "Sales & CRM",
  },
  {
    value: AgentType.support,
    label: "Support",
  },
  {
    value: AgentType.websiteAndAppBuilding,
    label: "Website & App Building",
  },
];

export function mapTypeToLabel(type: AgentType): string {
  const found = AgentTypes.find((t) => t.value === type);
  return found ? found?.label : "N/A";
}

export function mapLabelToType(label: string): AgentTypeWithLabel | undefined {
  const found = AgentTypes.find((t) => t.label === label);
  return found ? found : undefined;
}
