import { Route, Routes } from "react-router-dom";
import "./App.css";
import ActionGridContainer from "./components/action/ActionGridContainer";
import ViewAgentContainer from "./pages/agent/ViewAgentContainer";
import AgentsContainer from "./pages/agents/AgentsContainer";
import CheckoutContainer from "./pages/checkout/CheckoutContainer";
import CheckoutReturnContainer from "./pages/checkout/CheckoutReturnContainer";
import DesignContainer from "./pages/design/DesignContainer";
import DiscoverAgentsContainer from "./pages/discover/DiscoverAgentsContainer";
import DiscoverToolsContainer from "./pages/discover/DiscoverToolsContainer";
import HomeContainer from "./pages/home/HomeContainer";
import ViewProjectContainer from "./pages/project/ViewProjectDetails";
import CreateToolContainer from "./pages/tool/CreateToolContainer";
import ViewToolContainer from "./pages/tool/ViewToolContainer";
import ToolsContainer from "./pages/tools/ToolsContainer";
import ViewUserContainer from "./pages/user/ViewUserDetails";
import SignupSuccessContainer from "./pages/signup/SignupSuccessContainer";

function App() {
  return (
    <Routes>
      <Route path="*" element={<ActionGridContainer />} />
      <Route path="/discover/agents" element={<DiscoverAgentsContainer />} />
      <Route path="/discover/tools" element={<DiscoverToolsContainer />} />
      <Route path="/agents" element={<AgentsContainer />} />
      <Route path="/tool/create" element={<CreateToolContainer />} />
      <Route path="/tools" element={<ToolsContainer />} />
      <Route path="/tool/:id" element={<ViewToolContainer />} />
      <Route path="/user/:id" element={<ViewUserContainer />} />
      <Route path="/project/:id" element={<ViewProjectContainer />} />

      <Route path="/signup/success" element={<SignupSuccessContainer />} />

      <Route path="/agent/:agentId" element={<ViewAgentContainer />} />

      <Route path="/t" element={<HomeContainer />} />
      <Route path="/t/*" element={<HomeContainer />} />

      <Route path="/a/:agentId/t/:lcId" element={<HomeContainer />} />
      <Route path="/a/:agentId" element={<HomeContainer />} />

      <Route path="/design" element={<DesignContainer />} />
      <Route path="/checkout" element={<CheckoutContainer />} />
      <Route path="/checkout/return" element={<CheckoutReturnContainer />} />
    </Routes>
  );
}
export default App;
