import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import { AgentRow } from "../../../api/agent.type";
import { useSessionContext } from "../../fabrk/hooks/FabrkSessionContext";
import { useLeadContext } from "../../fabrk/hooks/LeadContext";
import { CopyToClipboardIcon } from "../../shared/icons/icons";
import { useAgentContext } from "../hooks/AgentContext";
import AgentHeaderPills from "./AgentHeaderPills";
import DiscoverListItemHeader from "./DiscoverListItemHeader";

function YourAgentsListItem({ agent }: { agent: AgentRow }) {
  const {
    id,
    fabrk_public,
    created_at,
    name,
    agent_fork,
    lead_agent,
    image_path,
  } = agent;

  const { fork } = useAgentContext();

  const { companyId } = useSessionContext();
  const { lead } = useLeadContext();

  return (
    <div
      key={id}
      className="min-w-sm flex max-w-lg flex-col items-start justify-between rounded-lg border-2 border-skin-hover bg-skin-hover p-4 shadow"
    >
      <DiscoverListItemHeader
        link={`/a/${id}/t/${id}`}
        createdAt={created_at}
        name={name}
        imagePath={image_path}
      >
        <AgentHeaderPills
          agentForkCount={agent_fork?.length ? agent_fork.length : 0}
          leadAgentCount={lead_agent?.length || 0}
        />
      </DiscoverListItemHeader>
      <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
        {fabrk_public ? (
          <Tooltip
            placement="bottom"
            content="This agent is public. Anyone can chat or fork this agent. This will make the agent private."
            className="bg-skin-hover text-skin-base"
          >
            <button
              type="button"
              className="inline-flex flex-shrink-0 items-center justify-center gap-2 rounded-md bg-skin-accentSecondary px-3 py-2 text-sm font-semibold text-skin-hover shadow-sm hover:bg-skin-accent sm:flex-1"
            >
              <EyeIcon className="h-6 w-6" />
            </button>
          </Tooltip>
        ) : (
          <Tooltip
            placement="bottom"
            content="This agent is private. Set this agent to public so others chat or fork it."
            className="bg-skin-hover text-skin-base"
          >
            <button
              type="button"
              className="inline-flex flex-shrink-0 items-center justify-center gap-2 rounded-md border-2 bg-skin-fill px-3 py-2 text-sm font-semibold text-skin-base shadow-sm hover:bg-skin-accentSecondary hover:text-skin-inverse sm:flex-1"
            >
              <EyeSlashIcon className="h-6 w-6" />
            </button>
          </Tooltip>
        )}

        <Tooltip
          placement="bottom"
          content="Copy this agent. This will create a copy of this agent including the tools."
          className="bg-skin-hover text-skin-base"
        >
          <button
            onClick={() =>
              fork({
                agentId: id,
                companyId: companyId,
                leadId: lead?.id as string,
              })
            }
            type="button"
            className="inline-flex flex-shrink-0 items-center justify-center gap-2 rounded-md border-2 bg-skin-fill px-3 py-2 text-sm font-semibold text-skin-base shadow-sm hover:bg-skin-accentSecondary hover:text-skin-inverse sm:flex-1"
          >
            <CopyToClipboardIcon className="h-4 w-4" />
            Copy
          </button>
        </Tooltip>
      </div>
    </div>
  );
}

export default YourAgentsListItem;
