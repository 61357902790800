/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { useSession } from "@supabase/auth-helpers-react";
import { getLeadById, updateLeadById } from "../../../api/lead.api";
import { LeadInsert, LeadRow, LeadWithRelations } from "../../../api/lead.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useSessionContext } from "./FabrkSessionContext";

export const LeadContextWrapper = (props: any) => {
  const { fabrkSession } = useSessionContext();

  const { setAlertProps } = useAlertContext();
  const queryClient = useQueryClient();

  const session = useSession();

  const [lead, setLead] = useState<LeadRow>();

  const { mutate: getLead } = useMutation(getLeadById, {
    onSuccess: async (res) => {
      setLead(res);
    },
    mutationKey: "getLead",
  });

  const { mutate: update } = useMutation(updateLeadById, {
    onSuccess: async (res) => {
      setLead(res);
      setAlertProps({
        message: "Client updated successfully",
        color: "green",
      });
      queryClient.invalidateQueries("leadAgents");
    },
    mutationKey: "updateLead",
  });

  useEffect(() => {
    if (!lead && fabrkSession && !session) {
      getLead(fabrkSession.lead_id);
    }
  }, [fabrkSession, session]);

  const value = { lead, update };

  return (
    <LeadContext.Provider value={value}>{props.children}</LeadContext.Provider>
  );
};

export const LeadContext = createContext({
  lead: {} as LeadRow | undefined,
  update: {} as UseMutateFunction<
    LeadWithRelations,
    unknown,
    {
      id: string;
      values: LeadInsert;
    },
    unknown
  >,
});

export const useLeadContext = () => useContext(LeadContext);
